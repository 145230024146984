import React, { useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import { useForm } from "react-hook-form";
import Button from "../Button";
import Transitions from "../Transitions";
import Anchor from "../Anchor";
import Link from "../Link";

const FormElement = styled.form`
    ${tw`w-full max-w-2xl mx-auto mt-8`};
`;

const FormLine = styled.div`
    ${tw`flex flex-wrap mb-3 -mx-3 last-of-type:mb-2`};
`;

const InputContainer = styled.div<{half?: boolean, third?: boolean, flex?: boolean}>`
    ${tw`relative w-full px-3`};

    ${p => p.half && tw`flex-grow w-full px-3 mb-6 md:w-1/2 md:mb-0`};
    ${p => p.third && tw`flex-grow w-full px-3 mb-6 md:w-1/3 md:mb-0`}
    ${p => p.flex && tw`self-center flex-1`};
`;

const FormLabel = styled.label<{checkbox?: boolean}>`
    ${tw`block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase`};
    ${p => p.checkbox && tw`inline ml-3 mb-3`};
`;

const FormInput = styled.input<{error?: boolean; checkbox?: boolean}>`
    ${p => !p.checkbox && tw`block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white`};

    ${p => p.error && tw`border border-red-500`};
    ${p => p.checkbox && tw`inline py-3 px-3 border border-gray-200`};
`;

const FormTextarea = styled.textarea<{error?: boolean}>`
    ${tw`block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white`};
    ${p => p.error && tw`border border-red-500`};
`;

const FormAlert = styled.p<{error?: boolean}>`
    ${tw`absolute text-xs italic text-gray-600 -bottom-2`};
    // ${p => p.error && `color: #750102`};
    ${p => p.error && tw`text-red-500`};
`;

const FormButton = styled(Button)<{error?: boolean; subscribed?: boolean}>`
    ${tw`flex items-center justify-center m-auto md:mt-3`};
    ${p => p.subscribed && tw`cursor-not-allowed`};
    ${p => p.subscribed && `&:hover { background-color: ${p.theme.colors.secondaryAccent};}`};
`;

const FormSelect = styled.select<{error?: boolean}>`
    ${tw`block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500 `};
    ${p => p.error && tw`border border-red-500`};
`;

const FormSelectArrow = styled.div`
    ${tw`absolute inset-y-0 right-0 flex items-center px-6 py-12 text-gray-700 pointer-events-none`};
`;

const SelectArrow = styled.svg`
    ${tw`w-4 h-4 fill-current`};
`;

const CheckMarkIcon = ({ fill = '#08080B' }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
        fill={fill}
      />
    </svg>
  );


const Form: React.FC<{}> = ({...props}) => {
    const [submitted, setSubmitted] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        errors,
        reset,
        formState: { isSubmitting }
    } = useForm();

    const required = 'Ezt a mezőt kötelező kitölteni';

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }
    
    const onSubmit = (data, e) => {
        data['PATHNAME'] = (typeof window !== `undefined`) ? window.location.pathname : '/';

        fetch(data['PATHNAME'], {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": e.target.getAttribute("name"),
                ...data
            })
        })
        .then((data) => {
            // console.log(data);

            setSubmitted(true);

            setTimeout(() => {
                setSubmitted(false);
                reset();
            }, 6000);
        })
        .catch(error => setError('submit', error));
    };


    return (
    <FormElement {...props} method="POST" onSubmit={handleSubmit(onSubmit)} netlify-honeypot="bot-field" name="Contact" data-netlify="true">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="Contact" />
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer half>
                <FormLabel htmlFor="form-first-name">
                    Keresztnév
                </FormLabel>
                <FormInput
                    id="form-first-name"
                    type="text"
                    name="firstName"
                    disabled={submitted || isSubmitting}
                    error={errors.firstName}
                    ref={register({ required , maxLength: 100 })} />
                {errors.firstName && <FormAlert error>{errors.firstName.message}</FormAlert>}
            </InputContainer>
            <InputContainer half>
                <FormLabel htmlFor="form-last-name">
                    Vezetéknév
                </FormLabel>
                <FormInput
                    id="form-last-name"
                    type="text"
                    name="lastName"
                    disabled={submitted || isSubmitting}
                    error={errors.lastName}
                    ref={register({ required , maxLength: 100 })} />
                {errors.lastName && <FormAlert error>{errors.lastName.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        {/* <FormLine>
            <InputContainer>
                <FormLabel htmlFor="form-password">
                    Password
                </FormLabel>
                <FormInput id="form-password" type="password" placeholder="******************" />
                <FormAlert>Make it as long and as crazy as you'd like</FormAlert>
            </InputContainer>
        </FormLine> */}
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer>
                <FormLabel htmlFor="form-email">
                    Email
                </FormLabel>
                <FormInput
                    id="form-email" 
                    type="email"
                    name="email"
                    disabled={submitted || isSubmitting}
                    error={errors.email}
                    ref={register({
                        required,
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })} />
                {errors.email && <FormAlert error>{errors.email.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer>
                <FormLabel htmlFor="form-company">
                    Cégnév
                </FormLabel>
                <FormInput
                    id="form-company"
                    type="text"
                    placeholder="Példa Kft."
                    name="company"
                    disabled={submitted || isSubmitting}
                    error={errors.company}
                    ref={register({ required , maxLength: 100 })} />
                {errors.company && <FormAlert error>{errors.company.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer half>
                <FormLabel htmlFor="form-phone">
                    Telefonszám
                </FormLabel>
                <FormInput
                    id="form-phone"
                    type="text"
                    name="phone"
                    disabled={submitted || isSubmitting}
                    error={errors.phone}
                    ref={register({ maxLength: 100 })} />
                {errors.phone && <FormAlert error>{errors.phone.message}</FormAlert>}
            </InputContainer>
            <InputContainer half>
                <FormLabel htmlFor="form-budget">
                    Költségvetés
                </FormLabel>
                <FormSelect
                    id="form-budget"
                    name="budget"
                    disabled={submitted || isSubmitting}
                    error={errors.budget}
                    ref={register({ maxLength: 100 })}>
                    <option value="Nincs">Nincs költségvetésem</option>
                    <option value="0-500">500.000 Ft-ig</option>
                    <option value="500-1000">500.000 Ft - 1.000.000 Ft között</option>
                    <option value="1000+">1.000.000 Ft felett</option>
                </FormSelect>
                <FormSelectArrow>
                    <SelectArrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                    </SelectArrow>
                </FormSelectArrow>
                {errors.budget && <FormAlert error>{errors.budget.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer>
                <FormLabel htmlFor="form-message">
                    Részletek
                </FormLabel>
                <FormTextarea
                    id="form-message"
                    type="text"
                    name="message"
                    disabled={submitted || isSubmitting}
                    error={errors.message}
                    ref={register({ maxLength: 256 })} />
                {errors.message && <FormAlert error>{errors.message.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer tw="pb-4">
                <FormInput
                    checkbox
                    type="checkbox"
                    name="accept" 
                    id="form-accept"
                    value="1" 
                    disabled={submitted || isSubmitting}
                    error={errors.accept}
                    ref={register({ required })} />
                <FormLabel checkbox htmlFor="form-accept">
                    Elfogadom az <Anchor as={Link} to="/adatvedelem">adatvédelmi szabályzatot.</Anchor>
                </FormLabel>
                {errors.accept && <FormAlert error>{errors.accept.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer flex>
                <FormButton
                    type="submit"
                    error={errors.submit}
                    subscribed={submitted}
                    disabled={submitted || isSubmitting}
                >
                    {submitted ? <CheckMarkIcon fill={'#fff'} /> : "Küldés"}
                </FormButton>
                {errors && errors.submit && <FormAlert>{errors.submit.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
    </FormElement>
    );
}

export default Form;
