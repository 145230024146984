import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import Select, { components } from 'react-select';

import {
  InstantSearch,
//   Hits,
  SortBy,
  HitsPerPage,
  Panel,
  Stats,
  Pagination,
  connectStats,
  connectSortBy,
  connectHitsPerPage,
  connectCurrentRefinements,
  ClearRefinements,
  RefinementList,
  Highlight,
  Configure,
  connectHits,
  connectSearchBox,
  connectRefinementList,
  ToggleRefinement,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
// import withURLSync from './URLSync';

import Image from "../Image/Image.Contentful";
import ImageNormal from "../Image";
import ImageStatic from "../Image/Image.Static";
// import Image from "../Image/Image.Static";
import Section from '../Section';
import Wrapper from '../Wrapper';

import './Search.css';
import { graphql } from 'gatsby';

const YOUTUBE_INDEX = 'influencers_youtube_ranking'
const INSTAGRAM_INDEX = 'influencers_instagram_ranking'
const TIKTOK_INDEX = 'influencers_tiktok_ranking'
const FACEBOOK_INDEX = 'influencers_facebook_ranking'

const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const excludeIrrelevantRecords = function(hits, field) {
    let list = [];
    for (let i = 0; i < hits.length; i++) {
        const element = hits[i];
        if (Object.prototype.hasOwnProperty.call(element, field) && Number(element[field]) > 0) {
            list.push(element)
        }
    }
    list = list.sort((a, b) => b[field] - a[field]);
    return list;
};

const youtubeRanking = searchClient.initIndex(YOUTUBE_INDEX).search('', {hitsPerPage: 1000}).then(({ hits }) => {
    const ranks = excludeIrrelevantRecords(hits, 'youtubeSubscribers');
    return ranks;
});

const instagramRanking = searchClient.initIndex(INSTAGRAM_INDEX).search('', {hitsPerPage: 1000}).then(({ hits }) => {
    const ranks = excludeIrrelevantRecords(hits, 'instagramFollowers');
    return ranks;
});

const tiktokRanking = searchClient.initIndex(TIKTOK_INDEX).search('', {hitsPerPage: 1000}).then(({ hits }) => {
    const ranks = excludeIrrelevantRecords(hits, 'tiktokFollowers');
    return ranks;
});

const facebookRanking = searchClient.initIndex(FACEBOOK_INDEX).search('', {hitsPerPage: 1000}).then(({ hits }) => {
    const ranks = excludeIrrelevantRecords(hits, 'facebookLikes');
    return ranks;
});


const Search: React.FC<{selected: Object}> = (selected) => {
    const [activeBar, setActiveBar] = useState<boolean>(false);

    return (
        <div id="influencer-search">
        <InstantSearch
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
            searchClient={searchClient}
            // searchState={props.searchState}
            // createURL={props.createURL}
            // onSearchStateChange={props.onSearchStateChange}
        >
            <Configure />
            <SearchBox />
            <CustomSection>
                <MobileFilterButtonContainer hidden={activeBar}>
                    <MobileFilterButton onClick={() => setActiveBar(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
                        <path
                        d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
                        stroke="#fff"
                        strokeWidth="1.29"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                    </svg>
                    Filterek
                    </MobileFilterButton>
                </MobileFilterButtonContainer>
                <CustomWrapper>
                    <SideBar flex active={activeBar}>
                        <FlexContainer sideBar>
                        <SearchTitle>Filterek</SearchTitle>
                        <ClearFilters>
                            <ClearRefinements
                            translations={{
                                reset: (
                                <>
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    style={{display: "flex", alignSelf: "center"}}
                                    >
                                    <g fill="none" fillRule="evenodd" opacity=".4">
                                        <path d="M0 0h11v11H0z" />
                                        <path
                                        fill="#000"
                                        fillRule="nonzero"
                                        d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                                        />
                                    </g>
                                    </svg>
                                    <SmallText>Filterek törlése</SmallText>
                                </>
                                ),
                            }}
                            />
                        </ClearFilters>
                        <CustomStats />
                        </FlexContainer>
                        <hr style={{width: '100%'}} />

                        <Panel id="categories" header="Témakörök">
                            {/* <RefinementListLinks attribute="fields.category.hu" /> */}
                            <RefinementList
                                attribute="category"
                                searchable={true}
                                showMore={true}
                                translations={{
                                    placeholder: 'Témakörök keresése…',
                                    showMore(expanded) {
                                        return expanded ? 'Kevesebb mutatása' : 'Több mutatása';
                                    }
                                }}
                            />
                        </Panel>
                        {/* <Panel header="Instagram">
                            <ToggleRefinement
                            attribute="fields.facebook.hu"
                            label="Mutasd csak azokat az influencereket, akiknek van Instagram profiljuk"
                            value={true}
                            />
                        </Panel> */}
                    </SideBar>
                    
                    <MobileSaveContainer active={activeBar}>
                        <CustomClearFiltersMobile clickFunc={() => setActiveBar(false)} />
                        <CustomSaveFilters onClick={() => setActiveBar(false)} />
                    </MobileSaveContainer>

                    <MainContent>
                        <Results selected={selected} />
                    </MainContent>
                </CustomWrapper>
            </CustomSection>
        </InstantSearch>
        </div>
    );
}

export default Search;

const SearchContainer = styled.div<{half?: boolean, third?: boolean}>`
    ${tw`w-full px-3 pt-8 m-auto mb-0 z-2 md:w-1/2 md:mb-0 md:-top-8 md:py-0`};
`;

const SearchBar = styled.input`
    ${tw`block w-full px-4 py-5 mb-3 leading-tight text-gray-800 bg-gray-100 border border-gray-400 rounded appearance-none focus:outline-none focus:bg-white`};
`;

const FilterSelect = styled.select`
    ${tw`p-1 my-1`};
`;

const SearchButton = styled.button`
    ${tw`absolute top-0 right-0 px-6 py-14 md:py-6`};
`;

const SearchSVG = styled.svg`
    ${tw`w-4 h-4 fill-current`};
    fill: #aaa;
`;

const CustomSection = styled(Section)`
    ${tw`z-10 pt-0 md:pt-0 lg:pt-0 sm:pt-0`};
`;

const CustomWrapper = styled(Wrapper)`
    ${tw`flex`};
`;

const SmallLogo = styled(ImageStatic)`
    ${tw`w-8 h-8 mr-2`};
`;

const MainContent = styled.div`
    ${tw`w-full md:w-9/12 `};
    // ${tw`w-full md:w-full `};
`;

const SmallText = styled.span`
    ${tw`p-2 text-sm`};
`;

const SideBar = styled.aside<{ flex?: boolean, active?: boolean }>`
    transition: ${p => p.theme.colorModeTransition};
    ${p => p.flex && tw`flex flex-col items-center`};
    ${tw`w-full bg-white md:bg-transparent md:pr-6 md:relative md:bottom-auto md:left-auto md:overflow-visible md:block md:w-3/12 md:h-full md:shadow-none md:rounded-tl-none md:rounded-tr-none md:py-0 md:pb-0`};

    ${tw`fixed bottom-0 left-0 z-20 w-full py-6 overflow-scroll transition-transform shadow-inner h-3/4 pb-14 rounded-tl-2xl rounded-tr-2xl`};

    ${p => !p.active ? tw`transform translate-y-full md:translate-y-0 md:flex` : tw`transform translate-y-0`};
    .ais-Panel {
        ${tw`py-8`};
    }

    .ais-RefinementList-item,
    .ais-Menu-item,
    .ais-HierarchicalMenu-item,
    .ais-RatingMenu-item {
        ${tw`select-none`};
    }

    .ais-RefinementList-item,
    /*
    The refinement list item in InstantSearch.js contains a wrapping 'div' because of
    the template behavior. We therefore need to apply the styles to all the elements
    in a refinement list.
    */
    .ais-RefinementList-item *,
    .ais-RatingMenu-item {
        ${tw`cursor-pointer`};
    }

    .ais-HierarchicalMenu-link,
    .ais-RatingMenu-item,
    .ais-RefinementList-item {
        // padding-bottom: 1rem;
        ${tw`pb-4`};
    }

    .ais-Breadcrumb-item--selected,
    .ais-HierarchicalMenu-item--selected,
    .ais-Menu-item--selected {
        ${tw`font-bold`};
    }

    .ais-SearchBox-input {
        ${tw`text-gray-800 bg-gray-100 border border-gray-400 rounded appearance-none focus:outline-none focus:bg-white`};
    }

    .ais-ClearRefinements-button {
        display: flex;
    }
`;

const MobileFilterButtonContainer = styled.div<{hidden?: boolean}>`
    ${p => p.hidden && tw`hidden`};
`;

const MobileFilterButton = styled.button`
    ${tw`fixed inset-x-0 z-20 flex items-center justify-center w-32 px-6 py-2 m-auto text-sm font-bold text-white bg-green-500 rounded-md cursor-pointer md:hidden bottom-8`};
    
    svg {
        ${tw`w-6 h-4 mr-1`};
    }
`;

const MobileSaveContainer = styled.div<{active?: boolean}>`
    ${tw`fixed inset-x-0 bottom-0 z-30 flex items-center justify-around hidden py-6 bg-white border-t`};
    ${p => p.active && tw`flex`}
`;

const ClearFilters = styled.div`
    ${tw`items-center hidden md:flex`};
`;

const MobileSaveFilters = styled.button`
    ${tw`px-4 py-2 m-auto text-white bg-green-500 rounded`};
`;

const SaveFilters = ({nbHits, onClick}) => (
    <MobileSaveFilters onClick={onClick}>
        <strong>{Number(nbHits).toLocaleString('hu')}</strong> találat
    </MobileSaveFilters>
)

const CustomSaveFilters = connectStats(SaveFilters);

const MobileClearFilters = styled.button`
    ${tw`flex px-4 py-2 m-auto text-gray-800 bg-gray-200 rounded`};
`;

const ClearFiltersMobile = ({ items, refine, clickFunc }) => {
    const onClick = () => {
        refine(items);
        clickFunc();
    }
  
    return (
        <MobileClearFilters onClick={onClick}>
            Filterek törlése
        </MobileClearFilters>
    );
};
  
const CustomClearFiltersMobile = connectCurrentRefinements(ClearFiltersMobile);

const MobileDisplayHitCount = styled.div`
    ${tw`md:hidden`};
`;

const ResultsNumberMobile = ({ nbHits }) => (
    <MobileDisplayHitCount>
        <strong>{Number(nbHits).toLocaleString('hu')}</strong> találat
    </MobileDisplayHitCount>
);

const CustomStats = connectStats(ResultsNumberMobile);

const Badge = styled.span`
    ${tw`inline-block px-2 py-1 m-1 text-xs font-semibold tracking-normal text-white uppercase bg-blue-500 rounded-full first-of-type:mt-3`};
`;

const Anchor = styled.a`
    ${tw`px-1 first-of-type:pl-0`}
`;

const LinkGrid = styled.div`
    ${tw`grid grid-cols-links grid-rows-links`};
`;

const LinkGridLine = styled.div`
    ${tw`bg-gray-400`};
`;

const Grid = styled.div`
    ${tw`grid grid-cols-1 gap-8 mt-20 gap-y-20 md:gap-y-16 md:grid-cols-2 xl:grid-cols-3`};
    ${tw`gap-8 mt-8 md:gap-8 sm:grid-cols-2 lg:grid-cols-3`};

    // ${tw`grid grid-cols-1 gap-8 mt-20 gap-y-20 md:gap-y-16 md:grid-cols-3 xl:grid-cols-4`};
    // ${tw`gap-8 mt-8 md:gap-8 sm:grid-cols-2 lg:grid-cols-4`};
`;

const CardImage = styled.div<{full?: boolean; color?: string; colorOpaque?: string; priority?: boolean}>`
    transition: ${p => p.theme.transitionValue};
    ${tw`h-48 min-w-full overflow-hidden rounded-t-md rounded-b-md`};
    ${p => p.full && tw`absolute h-full rounded-md`};
    ${p => p.priority && `border: 6px solid ${p.theme.colors.accent};`};
`;

const Card = styled.div<{priority?: boolean}>`
    // hover:transform hover:-translate-y-2
    ${tw`w-full h-full max-w-sm m-auto rounded-md md:max-w-full hover:z-2 hover:shadow-2xl`};
    transition: ${p => p.theme.transitionValue};
    background-color: ${p => p.theme.colors.card};

    // &:hover ${CardImage} {
    //     ${tw`transform scale-110`};
    // }
    ${p => p.priority && tw`border`};
    ${p => p.priority && `border: 2px solid ${p.theme.colors.accent}`};

`;

const InfluencerImage = styled(Image)`
    ${tw`min-w-full min-h-full`};
`;

const NoImage = styled(ImageNormal)`
    ${tw`min-w-full min-h-full`};
`;

const Content = styled.div`
    ${tw`w-full p-4 mx-auto my-0 md:p-4 z-2`};
`;

const Title = styled.h3`
    ${tw`mt-3 text-lg font-bold leading-none tracking-normal uppercase normal-case md:text-xl`};
`;

const Description = styled.p<{ empty?: boolean }>`
    ${tw`p-2 m-0 text-sm text-base leading-normal leading-7 text-center text-gray-900 md:text-sm lg:text-sm`};
    ${p => p.empty && tw`w-full h-full bg-gray-300`};
`;

const CustomFontAwesomeIcon = styled(FontAwesomeIcon)<{selectIcon?: boolean}>`
    ${tw`p-1 m-1 first-of-type:pl-0 first-of-type:ml-0 w-6 h-6`};
    ${p => p.selectIcon && tw`w-6 h-6 p-0 m-0 my-1 mr-2`};
`;

const PositionContainer = styled.div<{priority?: boolean}>`
    ${tw`absolute flex items-end w-full h-full`};
    ${p => p.priority && tw`items-start`}
`;

const Position = styled.span<{ color?: string; priority?: boolean }>`
    ${tw`px-2 py-1 m-4 mr-0 text-sm font-bold text-white bg-black rounded z-2`};
    background-color: ${p => p.color};
    ${p => p.priority && `background-color: ${p.theme.colors.accent}`};
`;

const SearchTitle = styled.h2`
    ${tw`flex my-4 text-lg font-bold md:text-2xl justify-self-start`};
`;

const StatContainer = styled.div`
    ${tw`absolute top-0 right-0`};
`;

const FlexContainer = styled.div<{ justifyNormal?: boolean, sideBar?: boolean }>`
    ${tw`flex flex-wrap items-center justify-between justify-self-end`};
    
    select {
        ${tw`text-sm border-0 outline-none appearance-none bg-none`};
    }

    ${p => p.justifyNormal && tw`justify-start`};
    ${p => p.sideBar && tw`w-full px-6 pb-4 border-b md:border-none md:pb-0 md:w-full md:px-0`};

    .ais-SortBy,
    .ais-HitsPerPage{
        ${tw`px-2`};
    }

    .ais-SortBy::after,
    .ais-HitsPerPage::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M14-5v16H-2V-5z'/%3E%3Cpath fill='%23000' stroke='%23FFF' stroke-width='.5' d='M2.228 1.332a.664.664 0 0 0-.942.001.665.665 0 0 0-.002.941l4.247 4.247c.259.26.679.26.938 0l4.247-4.247a.664.664 0 0 0-.002-.94.666.666 0 0 0-.942-.002L6 5.105 2.228 1.332z'/%3E%3C/g%3E%3C/svg%3E%0A");
        display: inline-block;
    }
`;

const HighlightText = styled.span`
    ${tw`text-lg font-bold`};
`;

const Category = ({ name }) => <Badge>{name}</Badge>;

const Categories = ({ categories }) => (
    <div>
    {categories.map((category, idx) => (
        <Category name={category} key={idx} />
    ))}
    </div>
);

const Hit = hit => {
    let fields = hit.hit;

    if ('fields' in hit.hit) {
        fields = hit.hit.fields;
        for (const field in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, field) && fields[field].hasOwnProperty('hu')) {
                fields[field] = fields[field]['hu'];
            }
        }
        hit.hit.fields = fields;
    }

    const {
        name, image, category,
        youtube, instagram, tiktok, facebook, 
        instagramFollowers, facebookLikes, youtubeSubscribers, tiktokFollowers,
        kiemeltInfluencer
    } = fields;

    const youtubeLink = (youtube && youtube.length == 24) ?
        'https://youtube.com/channel/' + youtube :
        'https://youtube.com/user/' + youtube;

    const formatNumber = (value) => {
        let number = Math.floor(Number(value));
        let multiplier = number.toString().length < 7 ? 'K' : 'M';
        let dividedNum = number.toString().length < 7 ? number / 1000 : number / 1000000;
        let finalNum = ( dividedNum % 1 !== 0 ) ? dividedNum.toFixed(1) : dividedNum;
        return finalNum.toLocaleString('hu') + multiplier;
    };

    let available = [];
    let elems = [];
    if (instagramFollowers > 0) { available.push({'Instagram': instagramFollowers, 'active': instagram}); }
    if (tiktokFollowers > 0) { available.push({'Tiktok': tiktokFollowers, 'active': tiktok}); }
    if (youtubeSubscribers > 0) { available.push({'Youtube': youtubeSubscribers, 'active': youtube}); }
    if (facebookLikes > 0) { available.push({'Facebook': facebookLikes, 'active': facebook}); }
    // available.push({'Instagram': instagramFollowers, 'active': instagram});
    // available.push({'Tiktok': tiktokFollowers, 'active': tiktok});
    // available.push({'Youtube': youtubeSubscribers, 'active': youtube});
    // available.push({'Facebook': facebookLikes, 'active': facebook});

    for (let i = 0; i < available.length; i++) {
        const element = available[i].active ?
            <Description>{Object.keys(available[i])[0]}<br />
                <HighlightText>{formatNumber(Object.values(available[i])[0])}</HighlightText>
            </Description> :
            null;

        elems.push(element);
        available.length >= 2 ? elems.push(<LinkGridLine />) : null;
        (i + 1) % 2 == 0 ? elems.push(<><LinkGridLine /><LinkGridLine /></>) : null;

        // const element = available[i].active ?
        //     <Description><b>{Object.keys(available[i])[0]}</b><br /> {Object.values(available[i])[0]}</Description> :
        //     <Description empty></Description>;
            
        // elems.push(element);
        // available.length > 2 ? elems.push(<LinkGridLine />) : null;
        // (i + 1) % 2 == 0 ? elems.push(<><LinkGridLine /><LinkGridLine /></>) : null;
    };


    return (
        <Card>
            <CardImage priority={kiemeltInfluencer}>
                <PositionContainer>
                {Object.keys(hit.ranks).length !== 0 && facebook in hit.ranks['facebook'] && facebook && <Position color="#1977F3">{hit.ranks['facebook'][facebook]}.</Position>}
                {Object.keys(hit.ranks).length !== 0 && instagram in hit.ranks['instagram'] && instagram && <Position color="#e1306c">{hit.ranks['instagram'][instagram]}.</Position>}
                {Object.keys(hit.ranks).length !== 0 && tiktok in hit.ranks['tiktok'] && tiktok && <Position color="#000000">{hit.ranks['tiktok'][tiktok]}.</Position>}
                {Object.keys(hit.ranks).length !== 0 && youtube in hit.ranks['youtube'] && youtube && <Position color="#ff0000">{hit.ranks['youtube'][youtube]}.</Position>}
                </PositionContainer>
                {kiemeltInfluencer &&
                    <PositionContainer priority>
                        <Position priority>Kiemelt</Position>
                    </PositionContainer>
                }
                {(instagram || image !== null) ?
                    <InfluencerImage src={image} alt={name} /> :
                    <NoImage src="https://dummyimage.com/640x360/ddd/aaa" alt={name} />}
            </CardImage>
            <Content>
                {/* <Highlight attribute="name" hit={hit.hit} /> */}
                <Title>{name}</Title>
                {facebook && <Anchor
                    as="a"
                    target="_blank"
                    href={"https://facebook.com/" + facebook}
                    rel="noopener nofollow"
                    data-a11y="false"
                    aria-label={`Link to https://facebook.com/` + facebook}
                ><CustomFontAwesomeIcon icon={faFacebookF} size="2x" color="#1977F3" /></Anchor> }
                {instagram && <Anchor
                    as="a"
                    target="_blank"
                    href={"https://www.instagram.com/" + instagram}
                    rel="noopener nofollow"
                    data-a11y="false"
                    aria-label={`Link to https://instagram.com/` + instagram}
                ><CustomFontAwesomeIcon icon={faInstagram} size="2x" color="#e1306c"  /></Anchor> }
                {tiktok && <Anchor
                    as="a"
                    target="_blank"
                    href={"https://tiktok.com/@" + tiktok}
                    rel="noopener nofollow"
                    data-a11y="false"
                    aria-label={`Link to https://tiktok.com/@` + tiktok}
                ><CustomFontAwesomeIcon icon={faTiktok} size="2x"  /></Anchor> }
                {youtube && <Anchor
                    as="a"
                    target="_blank"
                    href={youtubeLink}
                    rel="noopener nofollow"
                    data-a11y="false"
                    aria-label={`Link to ` + youtubeLink}
                ><CustomFontAwesomeIcon icon={faYoutube} size="2x" color="#ff0000" /></Anchor> }
                
                <LinkGrid>
                    {elems}
                </LinkGrid>
                {category ? <Categories categories={category} /> : null}
            </Content>
        </Card>
    );
};

const Hits = ({ hits }) => {
    const [ranks, setRanks] = useState<Object>({});

    useEffect(() => {
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (Object.keys(ranks).length == 0) {
            getPositions();
        }
    }, [ranks]);

    const getPositions = async () => {
        const facerank = await facebookRanking;
        const instarank = await instagramRanking;
        const tiktokrank = await tiktokRanking;
        const youtuberank = await youtubeRanking;

        let instaranks = {};
        for (let i = 0; i < instarank.length; i++) {
            const element = instarank[i];
            instaranks[element.instagram] = i + 1;
        }

        let faceranks = {};
        for (let i = 0; i < facerank.length; i++) {
            const element = facerank[i];
            faceranks[element.facebook] = i + 1;
        }

        let tiktokranks = {};
        for (let i = 0; i < tiktokrank.length; i++) {
            const element = tiktokrank[i];
            tiktokranks[element.tiktok] = i + 1;
        }

        let youtuberanks = {};
        for (let i = 0; i < youtuberank.length; i++) {
            const element = youtuberank[i];
            youtuberanks[element.youtube] = i + 1;
        }

        setRanks({
            instagram: instaranks,
            facebook: faceranks,
            tiktok: tiktokranks,
            youtube: youtuberanks
        });
    }

    const getPosition = async (hits, field, value) => {
        const a = await hits;
        console.log(a.findIndex(x => x[field] == value) + 1);
        return a.findIndex(x => x[field] === value) + 1;
    };

    return (
    <Grid>
        {hits.map(hit => (
            <Hit key={hit.objectID} hit={hit} ranks={ranks} />
        ))}
    </Grid>
    )
};
  
const CustomHits = connectHits(Hits);


const { Option } = components;

const CustomSelectOption = (props) => (
    <Option {...props}>
        <FlexContainer justifyNormal>
      {props.data.icon ? props.data.icon : null}
      {props.data.label}
      </FlexContainer>
    </Option>
);

const CustomSelectValue = props => (
    <FlexContainer justifyNormal>
      <span>{props.data.icon ? props.data.icon : null}</span>
      <span>{props.data.label}</span>
    </FlexContainer>
);

const SortByComponent = ({ items, refine, createURL, selectedVal}) => {
    const [selected, setSelected] = useState<Object>();

    useEffect(() => {
        if (Object.prototype.hasOwnProperty.call(selectedVal.selected, 'selected') &&
            selected && selected.value !== selectedVal.selected.selected.value) {
            const selectedObject =  items.find(obj => {
                return obj.value === selectedVal.selected.selected.value
            });
            if (selectedObject) {
                setSelected(selectedObject);
                refine(selectedObject.value);
            }
        };

        if (!selected) {
            setSelected(items[0]);
        }
    }, [selectedVal]);

    return (
    <FilterSelect
        as={Select}
        onChange={e => {
            setSelected({label: e.label, value: e.value, icon: e.icon});
            refine(e.value);
        }}
        closeMenuOnSelect={true}
        components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
        isSearchable={false}
        styles={{
            container: (provided, state) => ({ ...provided, zIndex: state.isFocused ? 11 : 10, minWidth: '250px', outline: '0', flex: 1 }),
            control: (provided, state) => ({
                ...provided,
                outline: '0',
                // border: 0,
                // This line disable the blue border
                boxShadow: 'none',
                color: 'rgba(45,55,72,1)',
                background: state.isFocused ? 'white': 'rgba(247,250,252, 1)',
                borderRadius: '0.25em',
                borderColor: 'rgba(203,213,224,1)',
                '&:hover': {
                    borderColor: 'rgba(203,213,224,1)'
                }
            }),
            menu: provided => ({ ...provided, zIndex: 11 }),
            menuPortal: provided => ({ ...provided, zIndex: 11 })
        }}
        value={selected}
        options={items}
    />
    )
};
  
const CustomSortBy = connectSortBy(SortByComponent);

const HitsPerPageComponent = ({ items, currentRefinement, refine, createURL }) => {
    const [selected, setSelected] = useState<Object>();

    useEffect(() => {
        if (!selected) {
            setSelected(items[0]);
        }
    }, [])

    return (
    <FilterSelect
        as={Select}
        onChange={e => {
            setSelected({label: e.label, value: e.value});
            refine(e.value)
        }}
        isSearchable={false}
        closeMenuOnSelect={true}
        styles={{
            container: (provided, state) => ({ ...provided, zIndex: state.isFocused ? 11 : 10, outline: '0', minWidth: '100px', flex: 1 }),
            control: (provided, state) => ({
                ...provided,
                outline: '0',
                // border: 0,
                // This line disable the blue border
                boxShadow: 'none',
                color: 'rgba(45,55,72,1)',
                background: state.isFocused ? 'white': 'rgba(247,250,252, 1)',
                borderRadius: '0.25em',
                borderColor: 'rgba(203,213,224,1)',
                '&:hover': {
                    borderColor: 'rgba(203,213,224,1)'
                }
            }),
            menu: provided => ({...provided, zIndex: 11, width: 'auto', minWidth: '100%',}),
            menuList: provided => ({...provided, zIndex: 11, width: 'auto', minWidth: '100%',}),
            menuPortal: provided => ({ ...provided, zIndex: 11, width: 'auto', minWidth: '100%' }),
        }}
        value={selected}
        options={items}
    />
    );
};

const CustomHitsPerPage = connectHitsPerPage(HitsPerPageComponent);

const Results = connectSearchBox((selected) => (
    <article>
        <FlexContainer>
            <SearchTitle>Eredmények</SearchTitle>
            <FlexContainer>
                {/* <Stats /> */}
                <CustomSortBy
                    selectedVal={selected}
                    defaultRefinement="influencers"
                    items={[
                    {
                        label: 'Legtöbbet keresett',
                        value: 'influencers',
                        icon: <SmallLogo src="logo.png" alt="iFaktor Agency Logo" />,
                    },
                    {
                        label: 'Instagram Rangsor',
                        value: 'influencers_instagram_ranking',
                        icon: <CustomFontAwesomeIcon icon={faInstagram} selectIcon size="sm" color="#e1306c"  />, 
                    },
                    {
                        label: 'Tiktok Rangsor',
                        value: 'influencers_tiktok_ranking',
                        icon: <CustomFontAwesomeIcon icon={faTiktok} selectIcon size="sm" color="#000000" />,
                    },
                    {
                        label: 'Youtube Rangsor',
                        value: 'influencers_youtube_ranking',
                        icon: <CustomFontAwesomeIcon icon={faYoutube} selectIcon size="sm" color="#ff0000" />,
                    },
                    {
                        label: 'Facebook Rangsor',
                        value: 'influencers_facebook_ranking',
                        icon: <CustomFontAwesomeIcon icon={faFacebookF} selectIcon size="sm" color="#1977F3" />,
                    },
                    ]}
                />
                <CustomHitsPerPage
                    items={[
                    {
                        label: '15 találat',
                        value: 15,
                    },
                    {
                        label: '30 találat',
                        value: 30,
                    },
                    {
                        label: '60 találat',
                        value: 60,
                    },
                    ]}
                    defaultRefinement={15}
                />
            </FlexContainer>
        </FlexContainer>

        <hr style={{width: '100%'}} />

        <div id="hits">
            {/* <Hits hitComponent={Hit} /> */}
            <CustomHits />
        </div>

        <PaginationContainer id="pagination">
            <Pagination
                padding={3}
                showFirst={true}
                showLast={true}
                translations={{
                previous: (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    >
                    <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                    >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                    </g>
                    </svg>
                ),
                next: (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    >
                    <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                    >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                    </g>
                    </svg>
                ),
                first: (
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.50001 9L5.10001 5L9.50001 1M13.9 5H5.10001H13.9Z" stroke="black"/>
                        <path d="M6.20001 1L2 5" stroke="black" stroke-linecap="square"/>
                        <path d="M6.3002 8.99979L2 5" stroke="black" stroke-linecap="square"/>
                    </svg>
                ),
                last: (
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9L9 5L5 1M1 5H9H1Z" stroke="black"/>
                        <path d="M8.10019 8.99979L12.3002 4.99979" stroke="black" stroke-linecap="square"/>
                        <path d="M8 1L12.3002 4.99979" stroke="black" stroke-linecap="square"/>
                    </svg>
                )
                }}/>
        </PaginationContainer>
    </article>
  ));

const PaginationContainer = styled.div`
    ${tw`mt-8`};
    .ais-Pagination-list {
        justify-content: center;
        display: flex;
    }
    .ais-Pagination-item,
    .ais-Pagination-link {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .ais-Pagination-item {
        height: 38px;
        width: 38px;
    }
    .ais-Pagination-item {
        background-color: rgba(65, 66, 71, 0.08);
        border-radius: 4px;
        color: #414247;
    }
    .ais-Pagination-item--selected {
        background-color: ${p => p.theme.colors.accent};
        background-image: linear-gradient(
            to bottom,
        rgba(255, 255, 255, 0.34),
        rgba(255, 255, 255, 0)
        );
        font-weight: bold;
    }
    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage,
    .ais-Pagination-item--lastPage {
        background: none;
    }
    .ais-Pagination-item--disabled {
        opacity: 0.33;
    }
    .ais-Pagination-item--selected a {
        color: #fff;
    }
    .ais-Pagination-item.ais-Pagination-item--page {
        margin-right: 4px;
    }
    .ais-Pagination-item.ais-Pagination-item--previousPage {
        margin-right: 1rem;
    }
    .ais-Pagination-item.ais-Pagination-item--nextPage {
        margin-left: calc(1rem - 4px);
    }
    .ais-Pagination-link {
        height: 100%;
        width: 100%;
    }
`;


const SearchBox = connectSearchBox(({ currentRefinement, refine }) => (
    <SearchContainer>
        <SearchBar type="text" value={currentRefinement} onChange={e => refine(e.target.value)} autoComplete="off" name="search" placeholder="Kereső" />
        <SearchButton type="submit" title="Submit your search query">
            <SearchSVG xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style={{enableBackground: "new 0 0 56.966 56.966"}} xmlSpace="preserve" width="512px" height="512px">
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
            </SearchSVG>
        </SearchButton>
    </SearchContainer>
));