import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Image from "./Image";

import { IImg } from "@types";

const ImageContentful: React.FC<IImg> = ({ src, alt, ...props }) => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulAsset {
                    nodes {
                        contentful_id
                        file {
                            url
                        }
                    }
                }
            }
        `}
        render={data => {
            const image = data.allContentfulAsset.nodes.find(
                node => node.contentful_id === src
            );
            if (!image) {
                return null;
            }

            const url = (
                image.file.url.includes('https:') ||
                image.file.url.includes('http:')
                ) ? image.file.url : 'https:' + image.file.url;

            return <Image src={url} alt={alt} {...props} />;
        }}
    />
);
export default ImageContentful;
