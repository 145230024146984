import React, { useEffect, useState } from "react";
// import { Link } from "gatsby";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";
import tw from "twin.macro";
import mq from "../styles/media";

import { useSiteMetadata } from '../hooks/useMetadata';

import SEO from "../components/SEO/SEO";
import Layout from "../components/Layout";

import Section from "../components/Section";
import Wrapper from "../components/Wrapper";
import Wave from "../components/Wave";
import SubscribeMailchimp from "../components/Subscribe/Subscribe.Mailchimp";

import Transitions from "../components/Transitions";
import Search from "../components/Search";
import Form from "../components/Form/Form.Netlify";
import Image from "../components/Image/Image.Static";

import InfluencersSVG from "@images/svg/influencers.inline.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { connectSortBy } from 'react-instantsearch-dom';


const InfluencersPage: React.FC<{}> = () => {
    const [selected, setSelected] = useState<Object>();
    const items = [
        {
            label: 'Legtöbbet keresett',
            value: 'influencers',
            icon: <IfaktorLogo src="logo.png" alt="iFaktor Agency Logo" />,
        },
        {
            label: 'Instagram Rangsor',
            value: 'influencers_instagram_ranking',
            icon: <CustomIcon icon={faInstagram} size="2x" color="#e1306c"  />, 
        },
        {
            label: 'Tiktok Rangsor',
            value: 'influencers_tiktok_ranking',
            icon: <CustomIcon icon={faTiktok} size="2x" color="#000000" />,
        },
        {
            label: 'Youtube Rangsor',
            value: 'influencers_youtube_ranking',
            icon: <CustomIcon icon={faYoutube} size="2x" color="#ff0000" />,
        },
        {
            label: 'Facebook Rangsor',
            value: 'influencers_facebook_ranking',
            icon: <CustomIcon icon={faFacebookF} size="2x" color="#1977F3" />,
        },
    ];

    useEffect(() => {
        if (!selected) {
            setSelected(items[0]);
        }
    }, []);

    return (
    <Layout>
        <SEO slug="/influencers" title="Magyarország Influencerei" isOrganization isMainPage />
        <Wrap>
            <InfluencerSection flex id="landing">
                <InfluencerWrapper left>
                    <Transitions.JS.FadeInUpGroup>
                        <Header>Magyarország kedvenc influenszerei</Header>
                        <Subtitle>
                        Kik a legnépszerűbb influenszerek? Kiket követnek legtöbben a különböző social média platformokon? Kivel érdemes együtt dolgozni a márkád építése során? Az iFaktor egyedülálló, témakörökre is kereshető, naponta frissülő adatbázisa választ ad erre!
                        </Subtitle>
                        <MySortBy 
                            defaultRefinement="influencers"
                            changeFunc={setSelected}
                            items={items}
                        />
                    </Transitions.JS.FadeInUpGroup>
                </InfluencerWrapper>
                
                <Transitions.JS.FadeInGroup duration={1000} delay={300} contents removeStylesAfter>
                    <InfluencersIcon right />
                </Transitions.JS.FadeInGroup>
            </InfluencerSection>

            <Search selected={selected} />

            <InfluencerSection form id="contact">
                <Wrapper>
                    <FlexContainer>
                        <Transitions.JS.FadeInUpGroup as={FlexItem}>
                            <Title custom>Ajánlatkérés:<br/> Influencer Kampány</Title>
                            <Description>
                            Mi nem csupán válaszokat, hanem megoldásokat is kínálunk! 
                            A legnagyobb influenszer hírportál működtetése mellett kampánykoncepciókat dolgozunk ki,
                            kampányokat menedzselünk a márkája számára leghitelesebb influenszerekkel.
                            Keressen minket, beszélgessünk a lehetőségekről!
                            </Description>
                        </Transitions.JS.FadeInUpGroup>
                        <ContactContainer as={FlexItem}>
                            <SmallTitle center>Kapcsolat</SmallTitle>
                            <Form />
                        </ContactContainer>
                    </FlexContainer>
                </Wrapper>
            </InfluencerSection>

            <SubscribeSection>
                <Wave top waveContext={{animated: false}} />
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center>Iratkozz Fel</Title>
                        <SubscribeDescription intro>
                            Ha érdekel mivel foglalkozunk iratkozz fel hírlevelünkre és értesülj a legfrissebb aktualitásunkról!
                        </SubscribeDescription>
                    </Transitions.JS.FadeInUpGroup>

                    <SubscribeMailchimp />
                </Wrapper>
            </SubscribeSection>
        </Wrap>
    </Layout>
    )
};

const InfluencerSection = styled(Section)<{form?: boolean; flex?: boolean}>`
    ${tw`bg-gray-200 top-16 md:top-0`};

    ${p => p.form && tw`mb-32 md:-mb-32`};
    ${p => p.flex && tw`md:py-20 lg:py-20 xl:py-24 lg:pb-16 xl:pb-20`};
`;

const FlexContainer = styled.div<{ justifyNormal?: boolean, sideBar?: boolean }>`
    ${tw`flex flex-col items-center justify-between md:flex-row`};
`;

const FlexItem = styled.div`
    ${tw`flex-1 w-full max-w-2xl m-auto md:m-8 md:first-of-type:ml-0 md:last-of-type:mr-0`};
`;

const Wrap = styled.div`
    ${tw`relative w-full h-full`};
`;

const dash = keyframes`
    to {
      stroke-dashoffset: -1000;
    }
`;

const ContactContainer = styled.div`
    ${tw`flex-auto max-w-xl px-6 py-4 m-auto bg-white rounded shadow-2xl`};
`;

const spanColorAccent = p => css`
    span {
        color: ${p.theme.colors.accent};
    }
`;

const textCenter = p => css`
    ${p.center && tw`text-center`};
`;

const Header = styled.h1`
    ${tw`text-4xl font-semibold leading-none tracking-tight md:text-5xl`};

    ${spanColorAccent};
`;

const Title = styled.h2<{center?: boolean; secondaryAccent?: boolean; custom?: boolean}>`
    ${tw`text-3xl font-semibold leading-none tracking-tight md:text-5xl`};
    
    ${textCenter};
    ${spanColorAccent};
    ${p => p.secondaryAccent && `span { color: ${p.theme.colors.secondaryAccent}; }`};
    ${p => p.custom && tw`text-center md:text-left`};
`;

const SmallTitle = styled.h3<{center?: boolean; secondaryAccent?: boolean}>`
    ${tw`mt-4 text-2xl font-semibold leading-none tracking-tight md:text-3xl`};

    ${textCenter};
    ${spanColorAccent};
    ${p => p.secondaryAccent && `span { color: ${p.theme.colors.secondaryAccent}; }`};
`;

const Subtitle = styled.p`
    ${tw`my-8 mb-4 text-lg text-gray-700 md:text-xl`};
`;

const Description = styled.p<{intro?: boolean}>`
    ${tw`my-8 text-base leading-7 text-center text-gray-700 md:text-left md:text-lg`};
    
    ${p => p.intro && tw`max-w-xl mx-auto text-center md:text-center`};
`;

const SubscribeSection = styled(Section)`
    ${tw`pt-2 mt-16 text-white sm:pt-2 md:pt-4 lg:pt-6 xl:pt-8 sm:mt-20 md:mt-24 lg:mt-32 xl:mt-40`};
    background: ${p => p.theme.colors.accent};
`;

const SubscribeDescription = styled(Description)`
    ${tw`text-green-100`};
`;


const Anchor = styled.a``;


const ContentSVG = css`
    ${tw`w-9/12 h-full max-w-full md:absolute md:w-4/12`};
`;

const RightSVG = css`
    ${tw`md:right-16`};
`;

const LeftSVG = css`
    ${tw`md:left-16`};
`;

const InfluencerWrapper = styled(Wrapper)`
    ${tw`md:w-8/12 lg:w-7/12`};
`;

const InfluencersIcon = styled(InfluencersSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};

    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;


const ListItem = styled.li`
    ${tw`px-1 rounded hover:bg-gray-300`};
`;

const SocialItems = styled.ul`
    ${tw`flex justify-center mb-6 md:mb-0 md:justify-start`};

`;

const IfaktorLogo = styled(Image)`
    ${tw`items-center w-8 h-8 p-3 m-2 mx-2 mt-3`};
`;

const CustomIcon = styled(FontAwesomeIcon)<{selectIcon?: boolean}>`
    ${tw`p-2 m-1`};
`;

const MySortBy = ({ items, changeFunc, defaultRefinement }) => {
    return (
    <div>
        <SocialItems>
        {items.map(item => (
            <ListItem key={item.value} onClick={() => changeFunc(item)}>
                <Anchor href="#influencer-search" aria-label={item.label} data-a11y="true">
                {item.icon}
                </Anchor>
            </ListItem>
        ))}
        </SocialItems>
    </div>
    );
};
  

export default InfluencersPage;